#whatwedosection {
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background-color: var(--white);
    transition: background-color var(--transition-time);
    position: absolute;
    width: 125vw;
    height: 13vh;
    border-radius: 100%;
    left: 50%;
    z-index: 10;
    top: 0;
    transform: translate(-50%, -70%);
    box-shadow: 0 -0.1em 10px 1px var(--aqua-dark);
  }
  &::after {
    content: "";
    background-color: var(--white);
    transition: background-color var(--transition-time);
    position: absolute;
    width: 125vw;
    height: 13vh;
    border-radius: 100%;
    left: 50%;
    z-index: 10;
    bottom: 0;
    transform: translate(-50%, 70%);
    box-shadow: 0 -0.1em 10px 1px var(--aqua-dark);
  }
  &.dark-mode {
    &::before {
      background-color: var(--gray);
      box-shadow: 0 -0.1em 10px 1px var(--aqua-very-light);
    }
    &::after {
      background-color: var(--gray);
      box-shadow: 0 -0.1em 10px 1px var(--aqua-very-light);
    }
  }
}
.div8 {
  text-align: center;
  position: absolute;
  top: 4%;
  justify-content: center;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.div8 p {
  font-size: 40px;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: white;
}

.divvv {
  position: absolute;
  top: 10%;
  width: 90%;
  height: 100%;
  margin-left: 8%;

  text-align: center;
  align-items: center;
}
.divvv p {
  font-size: 40px;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: white;
}
.div11 {
  text-align: center;
  position: relative;
  animation: fadeIn 3s;
  img {
    width: 75%;
    height: 75%;
    // transition: all 0.5s ease-in;
    animation: fadeIn 2s;
  }
  .text {
    position: relative;
    padding: 0.4rem;
    // text-align: center;
    text-align: start;
    display: none;
    // top: -38px;
    left: -50px;
    // transition: all 0.5s ease-in;
    animation: fadeIn 2s;

    border: 5px solid #05949b;
    p {
      margin: 0;
      font-size: 20px;
      // text-align: center;
      text-align: justify;
      text-justify: inter-word;
    }
  }
  // &:hover {
  //   .text {
  //     display: inline-block;
  //   }
  //   &:hover .img {
  //     display: none;
  //   }
  // }
  // &:hover + .div33.title {
  //   display: none;
  // }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.div55 {
  width: 15%;
  text-align: center;
  margin: 4%;
  &:hover {
    .text {
      display: inline-block;
    }
    .img {
      display: none;
    }
    .title {
      display: none;
    }
  }
}

.div22 {
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  height: auto;
}
.div33 {
  font-size: 34px;
  color: white;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  // transition: all 0.5s ease-in;
  animation: fadeIn 2s;
}

.test1 {
  position: absolute;
  top: 10%;
}

.back-image {
  position: relative;
  filter: brightness(40%);
}
.back-image img {
  width: 100%;
  height: 170vh;
  object-fit: cover;
}
.back-image .blur-filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

@media only screen and (min-width: 1601px) {
  .div11 {
    .text {
      width: 350px;
    }
  }
}

@media only screen and (min-width: 1401px) {
  .div11 {
    .text {
      width: 290px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .div33 {
    font-size: 25px;
    color: white;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  }
  .back-image img {
    width: 100%;
    height: 149vh;
    object-fit: cover;
  }
  .div11 {
    .text {
      width: 235px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .back-image img {
    width: 100%;
    height: 135vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1010px) {
  .div11 {
    .text {
      width: 225px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .div11 {
    .text {
      width: 215px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .back-image img {
    width: 100%;
    height: 120vh;
    object-fit: cover;
  }
  .div55 {
    &:hover .text {
      border-radius: 0;
      opacity: 1;
      cursor: pointer;
      width: 180px;
      height: auto;
      top: -20px;
      left: -40px;
    }
    .div11 {
      p {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 790px) {
  .div55 {
    &:hover .text {
      border-radius: 0;
      opacity: 1;
      cursor: pointer;
      width: 170px;
      height: auto;
      top: -20px;
      left: -40px;
    }
    .div11 {
      p {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1;
    transition-property: none;
    transition-property: none;
    /* transition-property: opacity,transform,-webkit-transform; */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 768px) {
  .divvv {
    display: "";
  }
  .divvv2 {
    display: none;
  }

  .divvv2 {
    position: absolute;
    top: 20%;
    width: 90%;
    height: 100%;
    margin-left: 8%;

    text-align: center;
    align-items: center;
  }
  .divvv2 p {
    font-size: 40px;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    color: white;
  }

  .div111 {
    text-align: center;
  }

  .div111 img {
    width: 80%;
    height: 70%;
  }

  .div555 {
    width: 13%;
    text-align: center;

    margin: 5%;
  }

  .div333 {
    font-size: 30px;
    color: white;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  }

  .div222 {
    text-align: center;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .divvv2 {
    display: "";
  }
  .divvv {
    display: none;
  }

  .divvv2 {
    position: absolute;
    top: 15%;
    width: 90%;
    height: 100%;
    margin-left: 8%;

    text-align: center;
    align-items: center;
  }
  .divvv2 p {
    font-size: 40px;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    color: white;
  }

  .div111 {
    text-align: center;
  }

  .div111 img {
    width: 90%;
    height: 70%;
  }

  .div555 {
    width: 26%;
    text-align: center;
    // margin: 4%;
    margin-left: 2%;
    margin-top: 4%;
    margin-bottom: 4%;
    margin-right: 4%;
  }

  .div333 {
    font-size: 20px;
    color: white;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  }

  .div222 {
    text-align: center;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
  }

  .back-image img {
    width: 100%;
    height: 200vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 700px) {
  .back-image img {
    width: 100%;
    height: 180vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 600px) {
  .back-image img {
    width: 100%;
    height: 127vh;
    object-fit: cover;
  }
}
