@font-face {
  font-family: "BTekUp-Font";
  src: url("fonts/ARA\ HAMAH\ KILANIA\ REGULAR.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

//Variables for Mobile version
:root {
  --aqua-very-light: #d5eef7;
  --aqua-light: #1bbed0;
  --aqua-mid: #05949b;
  --aqua-dark: #015866;
  --gray: #323b3f; /*Our Dark Mode Gray*/
  --white: #f9f9f9; /*Our White*/
  --margin: 5vw; /*left and right margins, applied were needed*/
  --margin-after-section: 7vw; /*margin after eact section (between sections)*/
  --margin-after-title: 3vw; /*margin after the title of each section (between title section and the section)*/
  --nav-font-size: 1.7rem;
  --title-font-size: 3rem;
  --large-font-size: 4rem; /*used in who we are for the big text*/
  --medium-font-size: 2rem; /*used in what we do skills category title*/
  --small-font-size: 1rem; /*used in who we are small text and skills under categories*/
  --transition-time: 0.2s; /*the time of all hover transitions*/
  --border-radius: 10px; /*used for rounded corners*/
  --shadow: 0 1px 3px 0 gray; //h-offset v-offset blur spread color
  --form-border-radius: 25px; /*used for rounded corners of the form*/
  --map-border-radius: 30px; /*used for rounded corners of the form*/
  --button-border-radius: 40px; /*used for rounded corners of the button*/
  --form-background: #fafafa; /*used for background of the text fields*/
  --hover-opacity: 0.7; /*used for hover effect*/
}
/* rem units are based on the body font-size(defined by the browser, 24px by default)
   2 rem = 48px
   em units are based on the font-size of the current element
   if font-size is not defined on an element it will inherit the body font size
*/

//Variables for Desktop Version
@media (min-width: 1024px) {
  :root {
    --margin: 10vw; /*left and right margins, applied were needed*/
    --margin-after-section: 5vh; /*margin after eact section (between sections)*/
    --margin-after-title: 2vh; /*margin after the title of each section (between title section and the section)*/
    --nav-font-size: 1.1rem;
    --title-font-size: 3rem;
    --large-font-size: 4rem; /*used in who we are for the big text*/
    --medium-font-size: 2rem; /*used in what we do skills category title*/
    --small-font-size: 1rem; /*used in who we are small text and skills under categories*/
    --transition-time: 0.2s; /*the time of all hover transitions*/
    --border-radius: 10px; /*used for rounded corners*/
    --shadow: 0 1px 3px 0 gray; //h-offset v-offset blur spread color
  }
}
body {
  overflow: auto !important;
  margin: 0;
  background-color: var(--white); /*Use var function to call the variable*/
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

button {
  background-color: transparent;
  border: none;
}

a {
  text-decoration: none;
}

#root {
  background-color: var(--white);
  transition: background-color var(--transition-time);
  &.dark-mode {
    background-color: var(--gray);
  }
}
