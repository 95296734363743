.c {
  margin-bottom: -3rem;
  &.darkMode {
    color: white;
    background-color: #323b3f;
    .time {
      color: white;
    }
    button {
      color: white;
    }
    .keywords {
      background-color: white;
      border: 2px solid #015866;
    }
  }
}

.container {
  a,
  a:hover,
  a:focus,
  a:active {
    color: #015866;
  }
  width: 70vw;
  margin: 3rem auto;
  font-family: "Roboto";
  overflow-x: hidden;
  overflow-y: hidden;

  h2 {
    .time {
      color: var(--gray);
      opacity: 0.8;
    }

    .category {
      color: var(--aqua-dark);
    }
  }

  .share {
    margin-top: 0.4rem;
    margin-bottom: 0;
  }

  .title {
    text-transform: uppercase;
    margin: 0;
  }

  .subtitle {
    margin-top: 0;
  }

  img {
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // max-height: 70vh;
  }
  .container--subtitle {
    display: flex;
    justify-content: space-between;
    width: 90%;
    .btns {
      display: flex;
      gap: 0.2rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        background-color: #015866;
        color: white;
        width: 35px;
        height: 35px;
        border-radius: 50%;

        &:hover {
          cursor: pointer;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .embed {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .keys {
    margin-bottom: 1.5rem;
    p {
      display: inline;
    }
    .keyword {
      // color: var(--aqua-dark);
      font-weight: 500;
      font-size: 16px;
      margin-right: 0.5rem;
    }
    .keywords {
      font-size: 14px;
      text-transform: uppercase;
      color: #015866;
      cursor: pointer;
      padding: 0.5rem 0.8rem;
      border: 1px solid #015866;
      border-radius: 22px;
      margin-right: 0.5rem;
      margin-bottom: 0.3rem;
      display: inline-block;
    }
  }
}

.b {
  text-align: justify;
  text-justify: inter-word;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 85vw;
  }
  .body {
    font-size: 1.1rem;
  }
  .img {
    width: 100vw;
    margin-top: -0.8rem;
  }
  .btns {
    button {
      padding: 9px 15px 6px;
    }
  }
}

@media only screen and (max-width: 430px) {
  .time,
  .category,
  .subtitle {
    font-size: 1.2rem;
  }
  .title {
    font-size: 1.5rem;
  }
  .container {
    .container--subtitle {
      width: 100%;
      .btns {
        button {
          width: 30px;
          height: 30px;
          // font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .imgContainer {
    width: 70vw;
    margin: 3rem auto;
    object-fit: contain;
    .img {
      width: 100%;
      margin-top: -0.8rem;
    }
  }
}
