@font-face {
  font-family: "BTekUp-Font";
  src: url("../../fonts/ARA\ HAMAH\ KILANIA\ REGULAR.TTF");
  font-weight: normal;
  font-style: normal;
}

body {
  background: var(--white);
}

#join-us {
  padding: 70px 0px;
  background: var(--white);
}

#image-container {
  margin-right: 4%;
}

#join-us.dark-mode {
  background-color: var(--gray);
}

#join-us.dark-mode h2 {
  color: var(--white);
}

#join-us.dark-mode #custom-text {
  color: var(--white);
}

#join-us.dark-mode #upload-button {
  background-color: var(--aqua-very-light);
  color: var(--gray);
  transition: var(--transition-time);
}

#join-us.dark-mode #submit-button {
  background-color: var(--aqua-light);
  color: var(--white);
  transition: var(--transition-time);
}

#join-us.dark-mode #upload-button:hover {
  opacity: var(--hover-opacity);
}

#join-us.dark-mode #submit-button:hover {
  opacity: var(--hover-opacity);
}

.section-title {
  text-align: center;
}
.section-title h2 {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: var(--aqua-dark);
  font-size: 50pt;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
  display: inline-block;
}
.section-title p {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: var(--aqua-dark);
}
#Fname,
#Lname,
#email {
  background: #fafafa;
  width: 25%;
  padding: 14.5px 15px;
  margin: 18px 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  color: #015866;
  box-shadow: 0 0 3px #1bbed0;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  font-size: large;
}

#join-us .cv {
  background-color: #015866;
  color: white;
  font-family: "BTekUp-Font";
  font-size: large;
  padding: 1rem 3rem;
  border-radius: 40px;

  &:hover {
    cursor: pointer;
    opacity: var(--hover-opacity);
  }
}

.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  flex-wrap: wrap;
}

#join-us textarea {
  width: 25%;
  height: 226px;
  padding: 10px 20px;
  margin: 15px 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px;
  box-shadow: 0 0 3px #1bbed0;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: #015866;
  font-size: large;
}

.form-control {
  height: 55px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  width: 100%;
  font-family: "BTekUp-Font";

  padding-left: 1.5%;
}

.form-group {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
}

#custom-text {
  margin-left: 1%;
}

.joinTeam {
  display: flex;
  flex-direction: row;
}

// #upload-button {
//   background-color: var(--aqua-mid);
//   border-radius: var(--button-border-radius);
//   color: var(--white);
//   width: 30%;
//   height: 50px;
//   margin-left: 0;
//   font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
//   font-size: 20px;
// }
#submit-button {
  background-color: var(--aqua-dark);
  border-radius: var(--button-border-radius);
  color: var(--white);
  padding: 1rem 4rem;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  font-size: 20px;
  &-disabled {
    background-color: grey;
    color: white;
    border-radius: var(--button-border-radius);
    padding: 1rem 4rem;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    font-size: 20px;
  }
}

#upload-button:hover {
  opacity: var(--hover-opacity);
}

#submit-button:hover {
  opacity: var(--hover-opacity);
  cursor: pointer;
}

#custom-text {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  font-size: 20px;
}

#image-and-title {
  width: 50%;
  h1 {
    margin-top: 0;
    margin-left: 3rem;
    width: 80%;
    text-align: center;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 400;
    color: var(--aqua-dark);
  }
}

#form-container {
  width: 45%;
  padding-top: 5%;
}

@media screen and (max-width: 992px) {
  .joinTeam {
    flex-direction: column;
    align-items: center;
  }
  #image-and-title {
    width: 100%;
    // margin: 0 auto;
  }
  #custom-text {
    text-align: center;
  }
  #form-container {
    width: 97%;
    padding-top: 5%;
  }

  #fcontainer {
    display: flex;
    flex-direction: column;
  }

  #flex-container {
    width: 90%;
    margin: 0 auto;
  }

  .form-control {
    height: 40px;
  }
}

@media screen and (max-width: 1300px) {
  #image-and-title {
    h1 {
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 450px) {
  #submit-button {
    padding: 0.7rem 3rem;
    font-size: 15px;
    &-disabled {
      padding: 0.7rem 3rem;
      font-size: 15px;
    }
  }
  #join-us .cv {
    font-size: 15px;
    padding: 0.7rem 2.4rem;
    font-size: 15px;
  }
}
