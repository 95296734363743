//Mobile Version
#footer {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 2em;
  background-color: var(--white);
  transition: background-color var(--transition-time);
  .footer-link {
    display: flex;
    margin-bottom: 1em;
    .footer-icon {
      background-color: var(--aqua-dark);
      width: 1.2em;
      height: 1.2em;
      min-width: 1.2em;
      min-height: 1.2em;
      padding: 0.6em;
      border-radius: 100%;
      transition: var(--transition-time);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1em;
        height: 1em;
        min-width: 1em;
        min-height: 1em;
        .cls-1 {
          z-index: 10;
          fill: white;
          opacity: 1;
        }
      }
    }
    &:hover {
      .footer-icon {
        opacity: 0.7;
      }
    }
  }
  .container {
    margin: 0 var(--margin);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .left-container,
  .right-container {
    margin-top: 1em;
  }
  .left-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    .footer-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 8rem;
      .footer-text {
        font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
        font-size: 1em;
        color: gray;
        text-align: center;
        margin-top: 0.2em;
      }
    }
  }
  .right-container {
    display: flex;
    justify-content: space-between;
    width: 10em;
  }
  .middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    .nav-logo {
      font-size: 1.5rem;
      width: 4em;
      margin: 0;
      img {
        width: 100%;
      }
    }
    .footer-copyright {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      color: #a0a0a0;
      text-align: center;
    }
  }
  &::before {
    content: "";
    background-color: var(--white);
    transition: background-color var(--transition-time);
    position: absolute;
    width: 125vw;
    height: 13vh;
    border-radius: 100%;
    left: 50%;
    z-index: 10;
    top: 0;
    transform: translate(-50%, -70%);
    box-shadow: 0 -0.1em 10px 1px var(--aqua-dark);
  }
  &.dark-mode {
    background-color: var(--gray);
    .footer-icon {
      background-color: var(--aqua-mid);
    }
    .left-container .footer-link .footer-text {
      color: white;
    }
    .middle-container .footer-copyright {
      color: var(--aqua-very-light);
    }
    &::before {
      background-color: var(--gray);
      box-shadow: 0 -0.1em 10px 1px var(--aqua-very-light);
    }
  }
}

// Desktop Version
@media (min-width: 1024px) {
  #footer {
    .container {
      flex-direction: row;
    }
    .left-container,
    .right-container {
      flex-direction: column;
      height: auto;
    }
    .left-container {
      align-items: flex-start;
      .footer-link {
        flex-direction: row;
        width: 12em;
        height: 3em;
        .footer-text {
          margin-top: 0;
          margin-left: 0.3em;
          text-align: start;
        }
      }
    }
    .right-container {
      align-items: flex-end;
      .footer-link {
        height: 3em;
        justify-content: center;
        align-items: center;
      }
    }
    .middle-container {
      height: auto;
      align-self: flex-end;
      .nav-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5em;
        height: 2em;
        margin-bottom: 0.6em;
      }
      .footer-copyright {
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

#iconwhats {
  color: white;
  font-size: 21px;
}
