#Circ {
  transition: transform 0.2s;
  position: relative;
}
@keyframes animate-up {
  100% {
    transform: translateY(-45%);
  }
}

.up-animation {
  animation: animate-up 1.5s ease-out forwards;
}

@keyframes animate-down {
  100% {
    transform: translateY(50%);
  }
}

.down-animation {
  animation: animate-down 1.5s ease-out forwards;
}
