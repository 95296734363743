.admin-card {
  padding: 3rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  background-color: #3a3ad9;
  width: 300px;
  height: 300px;
  border: 1px solid black;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.card__title {
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.card__button {
  background-color: white;
  border: 1px solid black;
  padding: 8px 16px;
  border-radius: 10px;
}
