@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

.navbar {
  margin-top: 47px;
  font-size: 1rem;
  line-height: 1.5;
  font-family: Roboto, sans-serif;
  // position: fixed;
  top: 0;
  width: 100vw;
  // height: 60px;
  background: #fff;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.12), 0 8px 6px rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #323b3f;
  z-index: 100;
  &.darkMode {
    background: #323b3f;
  }
}

.list {
  list-style-type: none;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding-top: 0.25rem;
  padding-bottom: 0.55rem;
  &.darkMode {
    background: #323b3f;
  }
}

.items {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 16px;
  text-transform: uppercase;
  color: #015866;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid #015866;
  border-radius: 22px;
  &.darkMode {
    background-color: #fff;
  }
}

.singleItem {
  margin-right: 20px;
  font-size: 16px;
  text-transform: uppercase;
  color: #015866;
  cursor: pointer;
}
.singleList {
  list-style-type: none;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.btn {
  display: none;
  position: absolute;
  right: 10px;
  top: 60px;
  padding: 5px;
  color: #000;
  font-size: 18px;
  background-color: white;
  border: 1px solid black;
}

.logo {
  width: 4rem;
  display: inline-block;
}

.logo--small {
  width: 3.3rem;
  display: none;
  position: absolute;
  top: -12px;
  left: 60px;
}

// @media screen and (max-width: 600px) {
//   .list {
//     flex-direction: column;
//     height: auto;
//     z-index: 101;
//   }
//   .items:nth-child(1) {
//     // display: none;
//     margin-top: 50px;
//     border-top: 1px solid #015866;
//   }
//   .items {
//     width: 100%;
//     text-align: center;
//     margin-right: 0px;
//     padding: 20px 0;
//     border-radius: 0;
//     border: 0;
//     border-bottom: 1px solid #015866;
//   }
//   .btn {
//     display: block;
//     color: #015866;
//     border-radius: 5px;
//     border: 1px solid #015866;
//   }
//   .logo--small {
//     display: inline-block;
//     &:hover {
//       cursor: pointer;
//     }
//   }
// }
