// @media only screen and (min-width: 1025px) and (max-width: 1300px) {
//   #LetsStartYourProject {
//     height: 60vh;
//   }
// }

@media only screen and (min-width: 1024px) {
  #LetsStartYourProject {
    height: 90vh;
    overflow: hidden;
    &.dark-mode {
      background-color: var(--gray);
    }

    .form {
      right: -10%;
      position: relative;
    }
    h1 {
      font-size: 50px;
      color: #015866;
      padding-left: 0%;
    }
    h4 {
      font-size: 30px;
      color: #1bbed0;
    }
    img {
      padding: -90px;
      border: 0px;
      margin: -45px;
      margin-bottom: -55px;
    }
    input[type="text"] {
      width: 25%;
      padding: 14.5px 15px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }

    input[type="Email"] {
      width: 25%;
      padding: 14.5px 15px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }

    input[type="number"] {
      width: 25%;
      padding: 14.5px 15px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }
    input[type="budget"] {
      width: 25%;
      padding: 14.5px 15px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
    }
    .col1 {
      padding-left: 10%;
    }
    ::-webkit-input-placeholder {
      color: #015866;
      font-size: large;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    }
    ::-webkit-textarea-placeholder {
      color: #015866;
      font-size: large;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    }

    textarea[type="textbox"] {
      width: 25%;
      height: 226px;
      padding: 10px 20px;
      margin: 15px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      border: 1px;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      color: #015866;
      font-size: large;
    }

    .textfield {
      position: relative;
      top: -700px;
      margin-right: 10%;
      left: 59%;
      margin-left: 5%;
    }
    #submit {
      background-color: var(--aqua-mid);
      border: none;
      color: black;
      padding: 1%;
      text-align: center;
      text-decoration: none;
      display: inline-block;

      border-radius: 25px;

      margin-top: -10px;
      &:hover {
        cursor: pointer;
        opacity: var(--hover-opacity);
      }
    }

    select {
      width: 25%;
      padding: 13.5px 15px;
      margin: 18px 0;
      border-color: #05949b;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: rgb(10, 10, 10);
      box-shadow: 0 0 3px #1bbed0;
    }
    .option {
      color: #015866;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
      position: relative;
      left: 30%;
      top: -350px;
      margin-right: 5%;
    }
    #title {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      &.dark-mode {
        color: white;
      }
    }
    .title2 {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      left: 0%;
      position: relative;
    }
    .container {
      background-color: #015866;
      border-style: hidden;
      border-radius: 15px;
      padding: 18px;
      text-align: center;
      box-shadow: 0 8px 6px -6px black;
    }
    #submit {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      color: white;
      font-size: x-large;
      position: relative;
      left: 72%;
      width: 10%;
      margin-left: 2%;
      top: -670px;
    }
  }
}
/*Variables for Mobile version*/
@media only screen and (max-width: 1024px) {
  #LetsStartYourProject {
    width: 85%;
    margin: 0 auto;
    &.dark-mode {
      background-color: var(--gray);
    }

    // .form {
    //   right: -15%;
    //   position: relative;
    // }

    h1 {
      font-size: 2.5rem;
      color: #015866;

      position: relative;
    }
    h4 {
      font-size: 2rem;
      color: #1bbed0;

      position: relative;
    }
    img {
      padding: -90px;
      border: 0px;
      margin: -40px;
      margin-bottom: -55px;
    }
    input[type="text"] {
      width: 100%;
      padding: 18px 20px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }
    input[type="number"] {
      width: 100%;
      padding: 18px 20px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }
    input[type="Email"] {
      width: 100%;
      padding: 18px 20px;
      margin: 18px 0;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }
    input[type="budget"] {
      width: 100%;
      padding: 18px 20px;
      margin: 18px 0;
      outline: none;
      box-sizing: border-box;
      border: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: #015866;
      box-shadow: 0 0 3px #1bbed0;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: large;
    }
    .col1 {
      width: 80%;
      left: 0%;
      position: relative;
      margin-left: 10%;
    }
    select {
      width: 100%;
      padding: 18px 20px;
      margin: 18px 0;
      border-color: #05949b;
      outline: none;
      box-sizing: border-box;
      border: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      color: rgb(10, 10, 10);
      box-shadow: 0 0 3px #1bbed0;
    }
    textarea[type="textbox"] {
      width: 100%;
      padding: 18px 20px;
      left: 30px;
      margin: 15px 0;
      outline: none;
      box-sizing: border-box;
      border: none;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      border: 1px;
      box-shadow: 0 0 3px #1bbed0;
      margin-top: 12%;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      color: #015866;
      font-size: large;
    }
    .option {
      color: #015866;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: medium;
      left: 10%;
    }
    ::-webkit-input-placeholder {
      color: #015866;
      font-size: medium;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    }
    /*input::placeholder {
    opacity: 0.9;
    color:#015866;
    font-size: medium;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  }*/
    ::-webkit-textarea-placeholder {
      color: #015866;
      font-size: medium;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    }
    #submit {
      background-color: #1bbed0;
      border: none;
      color: black;
      padding: 13px 50px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      /* cursor: pointer; */
      border-radius: 25px;
    }
    #title {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      // width: 70%;
      display: flex;
      justify-content: center;
      &.dark-mode {
        color: white;
      }
    }
    .title2 {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      // width: 70%;
      display: flex;
      justify-content: center;
    }
    #submit {
      margin-top: 20px;
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      color: white;
      font-size: x-large;
      position: relative;
      width: 30%;
      display: flex;
      justify-content: center;
      margin: auto;
      float: right;
      margin-right: 30%;
    }
    .container {
      background-color: #015866;
      border-style: hidden;
      border-radius: 15px;
      padding: 18px;
      box-shadow: 0 8px 6px -6px black;
    }
  }
}
