@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

.overflow {
  overflow: auto;

  &.darkMode {
    background-color: #323b3f;
  }
}

.blog {
  font-size: 1rem;
  line-height: 1.5;
  font-family: Roboto, sans-serif;
  width: 84%;

  box-sizing: border-box;
  margin: 0 auto;
}

.container {
  // margin: 4rem auto 3rem;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.mb {
  margin: 5rem 0rem;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.more--cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.show--more {
  width: 12rem;
  margin: 1rem auto;
  button {
    padding: 1rem 2.5rem;
    color: white;
    background-color: #015866;
    border: 1px solid #015866;
    border-radius: 15px;
    font-size: 1.3rem;
    &:hover {
      scale: (1.05);
      opacity: 0.9;
      cursor: pointer;
    }
  }
}

.grid {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas:
    "one"
    "two"
    "three"
    "four"
    "five"
    "six";

  .card:nth-child(1) {
    grid-area: one;
  }

  .card:nth-child(2) {
    grid-area: two;
  }

  .card:nth-child(3) {
    grid-area: three;
  }

  .card:nth-child(4) {
    grid-area: four;
  }

  .card:nth-child(5) {
    grid-area: five;
  }

  .card:nth-child(6) {
    grid-area: six;
  }
}

.grid2 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas:
    "one"
    "two"
    "three"
    "four"
    "five"
    "six";

  .card:nth-child(1) {
    grid-area: one;
  }

  .card:nth-child(2) {
    grid-area: two;
  }

  .card:nth-child(3) {
    grid-area: three;
  }

  .card:nth-child(4) {
    grid-area: four;
  }

  .card:nth-child(5) {
    grid-area: five;
  }

  .card:nth-child(6) {
    grid-area: six;
  }
}

@media screen and (max-width: 800px) {
  .more--cards {
    justify-content: center;
  }
  .blog {
    max-width: 630px;
  }
  .mb {
    margin: 3rem auto;
    justify-content: center;
    gap: 2rem;
  }
}

@media screen and (min-width: 800px) {
  .grid2 {
    grid-template-areas:
      "one one"
      "two three"
      "four five"
      "six six";
  }
  .grid {
    grid-template-areas:
      "one one"
      "two three"
      "four five"
      "six six";
  }
}

@media screen and (min-width: 1100px) {
  .grid2 {
    grid-template-areas:
      "two one one"
      "two four five"
      "three four five"
      "three six six";
  }
  .grid {
    grid-template-areas:
      "one one two"
      "four five two"
      "four five three"
      "six six three";
  }
  .blog {
    width: 955px;
  }
}

@media screen and (min-width: 1400px) {
  .grid2 {
    grid-template-areas:
      "two three one one"
      "two three four five"
      "six six four five";
  }
  .grid {
    grid-template-areas:
      "one one two three"
      "four five two three"
      "four five six six";
  }
  .blog {
    width: 1300px;
  }
}
