@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;0,900;1,700&display=swap");

.wrapper {
  h1,
  h2,
  p,
  hr {
    margin: 0;
    font-family: "Roboto", sans-serif;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    color: #015866;
  }

  box-sizing: border-box;
  &.darkMode {
    background: #323b3f;
    color: white;
    .size__btn {
      button {
        color: white;
      }
    }
    a,
    a:hover,
    a:focus,
    a:active {
      color: #05949b;
    }
  }

  .navbar {
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0 8px 6px rgba(0, 0, 0, 0.12), 0 8px 6px rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid #323b3f;
  }

  .logo {
    width: 4rem;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }

  .leader {
    .leader__img {
      object-fit: cover;
      object-position: bottom;
    }

    .leader__info {
      // max-width: 37.5rem;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__icons {
          display: flex;
          flex-wrap: wrap;

          a {
            &:hover {
              .icon {
                opacity: 0.7;
                transform: scale(1.1);
              }
            }
          }

          .header__icon {
            display: flex;
            margin-left: 1em;
            .icon {
              background-color: var(--aqua-dark);
              width: 1.8em;
              height: 1.8em;
              min-width: 1.2em;
              min-height: 1.2em;
              padding: 0.6em;
              border-radius: 100%;
              transition: var(--transition-time);
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 1.6em;
                height: 1.6em;
                min-width: 1em;
                min-height: 1em;
                .cls-1 {
                  z-index: 10;
                  fill: white;
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .leader__title {
        width: 200px;
        h1 {
          font-weight: 900;
          text-transform: uppercase;
          letter-spacing: 5px;
          span {
            display: block;
          }
        }
        .line {
          border-top: 1px solid black;
          margin-top: 0.2rem;
          margin-bottom: 0.6rem;
        }
      }

      .leader__subtitle {
        font-weight: 700;
        &Italic {
          font-weight: 700;
          font-style: italic;
        }
      }

      .leader__text {
        font-size: 16px;
        margin-top: 1rem;
        line-height: 150%;
      }
    }
  }
  .size__btn {
    display: inline-block;
    margin-left: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-size: 18px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

// @media (max-width: 1550px and min-width: ) {
//   .leader {
//     width: 95%;
//     margin: 3rem auto;
//     background-color: red;
//   }
// }

@media (min-width: 1009px) {
  .leader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 90%;
    margin: 0 auto;
    padding-top: 4rem;
    .leader__img {
      width: 50%;
      &.hide {
        display: none;
      }
    }
    .leader__info {
      width: 45%;
    }
    .leader__text {
      font-size: 13px;
      text-align: justify;
      text-justify: inter-word;
    }
  }
}

@media (max-width: 1008px) {
  .leader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding-top: 4rem;

    .header,
    .leader__text {
      width: 90%;
      margin: 0 auto;
    }

    .leader__img {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      &.hide {
        display: block;
      }
      &.display {
        display: none;
      }
    }
    .leader__text {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 450px) {
  .header__icons {
    flex-direction: column;
    .header__icon {
      margin-bottom: 0.3rem;
    }
  }
}
