#skillsSection {
  &.dark-mode {
    background-color: var(--gray);
    margin-bottom: -4.77rem;
  }
}

#class2 {
  font-family: "BTekUp-Font", Arial, Helvetica;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  margin-top: -2.2%;
  color: #343b3f;

  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  &.dark-mode {
    color: white;
  }
}
.skillsimage1 {
  float: left;
  width: 50%;
  height: 95%;
  margin-left: 6%;
  margin-top: 3%;
}

.st0 {
  fill: #015866;
}
.st1 {
  fill: #05949b;
}
.st2 {
  fill: #ffffff;
}
.st0:hover {
  fill: red;
}

#skillstitle {
  color: #015866;
  text-align: center;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  margin: 0 auto;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  &.dark-mode {
    color: white;
  }
}
.divskillstitle {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.div1 {
  width: 15%;
  margin-top: -4%;
  margin-bottom: -4%;
}
.div2 {
  width: 70%;

  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Appp {
  display: flex;
  flex-direction: row;
}

.div3 {
  width: 60%;

  justify-content: center;
  padding-top: 2%;
  height: 100%;
  margin-left: 4%;
}

#h3 {
  color: #015866;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  &.dark-mode {
    color: #05949b;
  }
}

.img2 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.st0 {
  fill: #015866;
}
.st1 {
  fill: #05949b;
}
.st22 {
  fill: #ffffff;
}

#st3 {
  fill: #323b3f;
  &.dark-mode {
    fill: #d5eef7;
  }
}

.st4 {
  clip-path: url(#SVGID_2_);
  fill: url(#SVGID_3_);
}
.st5 {
  clip-path: url(#SVGID_5_);
  fill: url(#SVGID_6_);
}

.class2 {
  font-family: "BTekUp-Font", Arial, Helvetica;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  margin-top: -4%;
  color: #343b3f;

  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 884px) {
  .div2 {
    width: 70%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .skillsimage1 {
    width: 90%;
    height: 100%;
    float: right;

    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  #Layer_1 {
    margin-left: 12%;
  }

  .div3 {
    height: fit-content;
    width: 95%;
    margin: 0 auto;
    justify-content: center;

    margin-top: 10%;
  }

  .class2 {
    font-family: "BTekUp-Font", Arial, Helvetica;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    margin-top: -4%;
    color: #343b3f;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
  }

  .h3 {
    color: #015866;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    font-size: 2rem;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
  }
}
