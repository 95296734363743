@font-face {
  font-family: "BTekUp-Font";
  src: url("../../fonts/ARA\ HAMAH\ KILANIA\ REGULAR.TTF");
  font-weight: normal;
  font-style: normal;
}

body {
  background: var(--white);
}

.st0 {
  fill: #ffffff;
}
.st1 {
  fill: none;
  stroke: #1bbed0;
}
.st2 {
  enable-background: new;
}
.st3 {
  fill: #015866;
}
.st4 {
  fill: #008494;
}
.st5 {
  fill: #2bb8cb;
}

#contact-us {
  padding: 70px 0px;
  background: var(--white);
}

.section-title {
  text-align: center;
}

.section-title h2 {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: var(--aqua-dark);
  font-size: 50pt;
  padding-bottom: 10px;
  display: inline-block;
}
.section-title p {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  color: var(--aqua-dark);
}
#contact-us input,
#contact-us textarea {
  background: var(--form-background);
  border-radius: 25px;
  border-color: var(--aqua-light);
  border-width: 1px;
  width: 100%;
}
#contact-us textarea {
  min-height: 188px;
  max-width: 100%;
}
#contact-us .map {
  overflow: hidden;
  border-radius: var(--map-border-radius);
}
.form-control {
  height: 70px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.form-group {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
}

#contact-us.dark-mode {
  background-color: var(--gray);
}
#contact-us.dark-mode h2 {
  font-size: 50pt;
  color: var(--white);
}

#contact-us.dark-mode #send-button {
  background-color: var(--aqua-light);
  border: 0px;
}

#join-us.dark-mode #send-button:hover {
  opacity: var(--hover-opacity);
}

iframe {
  border-radius: var(--map-border-radius);
  border: 0px;
}

.location-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 55%;
  padding-top: 2%;
  padding-left: 3%;
  background-color: transparent;
}

.email-icon {
  position: relative;
  align-self: center;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -2rem;
  z-index: 110;
  .subEmailIcon {
    background-color: white;
    border: 1px solid #1bbed0;
    border-radius: 50%;
    color: #1bbed0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10;
    cursor: pointer;
    width: 170px;
    height: 170px;
    .iconSVGWH {
      height: 40px;
      width: 40px;
    }
    .textSubEmailIcon {
      font-family: BTekUp-Font;
      font-weight: normal;
      font-style: normal;
      font-size: 1.2rem;
    }
  }
}

.container {
  padding: 1%;
  margin: 5%;
}

.form-row {
  display: flex;
}

.form-control {
  font-size: 20px;
}

#send-button {
  background-color: var(--aqua-mid);
  border-radius: var(--button-border-radius);
  color: var(--white);
  width: 30%;
  height: 50px;
  margin-left: 70%;
  margin-top: 5%;
  transition: var(--transition-time);
  font-size: 20px;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
}

#send-button:hover {
  opacity: var(--hover-opacity);
  cursor: pointer;
}

/*@media screen and (max-width: 991px) {
  .location-icon {
    width: 40%;
  }

  .email-icon {
    width: 40%;
  }
}*/

.getInTouch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#map-container {
  position: relative;
  margin-left: 10%;
}

@media screen and (max-width: 999px) {
  #send-button {
    margin-bottom: 5%;
  }

  .form-control {
    width: 100%;
    padding: 2%;
  }
}

@media screen and (max-width: 992px) {
  .getInTouch-container {
    flex-direction: column;
  }
  #map-container {
    margin-left: 0;
  }
}
.stt0 {
  fill: #ffffff;
}
.stt1 {
  fill: none;
  stroke: #1bbed0;
}
.stt2 {
  enable-background: new;
}
.stt3 {
  fill: #015866;
}
.stt4 {
  fill: #2bb8cb;
}
.stt5 {
  fill: #008494;
}
.stt6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #2bb8cb;
}
.stt4:hover {
  fill: rgba(43, 184, 203, 0.5);
}
#imagesvg {
  width: 40vh;
  margin-top: -4vh;
  position: relative;
}
.stt6:hover {
  fill: rgba(43, 184, 203, 0.5);
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .email-icon {
    align-self: center;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -2rem;
    .subEmailIcon {
      background-color: white;
      border: 1px solid #1bbed0;
      border-radius: 50%;
      color: #1bbed0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10;
      cursor: pointer;
      width: 150px;
      height: 150px;
      .iconSVGWH {
        height: 40px;
        width: 40px;
      }
      .textSubEmailIcon {
        font-family: BTekUp-Font;
        font-weight: normal;
        font-style: normal;
        font-size: 1rem;
      }
    }
  }
}

@media screen and (min-width: 980px) and (max-width: 1199px) {
  .email-icon {
    align-self: center;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -2rem;
    .subEmailIcon {
      background-color: white;
      border: 1px solid #1bbed0;
      border-radius: 50%;
      color: #1bbed0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10;
      cursor: pointer;
      width: 120px;
      height: 120px;
      .iconSVGWH {
        height: 30px;
        width: 30px;
      }
      .textSubEmailIcon {
        font-family: BTekUp-Font;
        font-weight: normal;
        font-style: normal;
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 651px) and (min-width: 979px) {
  .email-icon {
    position: relative;
    z-index: 110;
  }
}

@media screen and (max-width: 650px) and (min-width: 391px) {
  .email-icon {
    align-self: center;
    background-color: transparent;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: -2rem;
    z-index: 110;
    .subEmailIcon {
      background-color: white;
      border: 1px solid #1bbed0;
      border-radius: 50%;
      color: #1bbed0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10;
      cursor: pointer;
      width: 110px;
      height: 110px;
      .iconSVGWH {
        height: 24px;
        width: 24px;
      }
      .textSubEmailIcon {
        font-family: BTekUp-Font;
        font-weight: normal;
        font-style: normal;
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .email-icon {
    align-self: center;
    background-color: transparent;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: -2rem;
    z-index: 110;
    .subEmailIcon {
      background-color: white;
      border: 1px solid #1bbed0;
      border-radius: 50%;
      color: #1bbed0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10;
      cursor: pointer;
      width: 100px;
      height: 100px;
      .iconSVGWH {
        height: 24px;
        width: 24px;
      }
      .textSubEmailIcon {
        font-family: BTekUp-Font;
        font-weight: normal;
        font-style: normal;
        font-size: 0.8rem;
        padding-bottom: 1.5rem;
      }
    }
  }
}
