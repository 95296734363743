
.parent {
    width: 70%;
    height: 100%;
    display: block;
    margin-left: 10%;
    margin-right: 20%;
    margin-top: 8rem;
    position: absolute;
  }
  .child{
    width: 70%;
    height: 100%;
    display: block;
    margin-left: 10%;
    margin-right: 20%;
    margin-top: 8rem;
    position: absolute
  }
  
  
  .sec1 {
    position: relative;
  }

  .Whowe
  {
    position: static;
    display: block;
  }