p {
  padding: 0;
  margin: 0;
}

.card {
  position: relative;
  overflow: hidden;
  display: flex;

  width: 300px;
  height: 450px;

  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.56s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }

  img {
    height: 120%;
    // max-width: 100%;
    display: block;
  }
}

.widecard {
  position: relative;
  overflow: hidden;
  display: flex;

  width: 630px;
  height: 300px;

  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.56s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
  img {
    width: 120%;
  }
}

.card__title {
  align-self: flex-end;
  padding-top: 0.5rem;
  // color: rgba(255, 255, 255, 0.9);
  color: white;
  font-size: 2rem;
  line-height: 1;
  font-weight: 600;
}

.card__thumbnail {
  position: relative;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: -30px;
    width: 200%;
    height: 200%;
  }

  .text {
    width: 90%;
    position: absolute;
    left: -1.3rem;
    bottom: 2rem;
  }

  .card__category {
    align-self: flex-end;
    // color: rgba(255, 255, 255, 0.8);
    color: white;
    font-size: 1.3rem;
    line-height: 1;
    font-weight: 400;
  }
}

@media screen and (max-width: 800px) {
  .card {
    width: 630px;
    height: 300px;
    img {
      width: 120%;
      height: auto;
    }
    margin: 0 auto;
  }
  .widecard {
    margin: 0 auto;
  }
}

@media screen and (max-width: 720px) {
  .card {
    width: 500px;
    height: 250px;
    img {
      width: 120%;
      height: auto;
    }
    margin: 0 auto;
  }
  .widecard {
    width: 500px;
    height: 250px;
  }
}

@media screen and (max-width: 590px) {
  .card {
    width: 400px;
    height: 200px;
    img {
      width: 140%;
      height: auto;
    }
    margin: 0 auto;
  }
  .widecard {
    width: 400px;
    height: 200px;
    img {
      width: 140%;
    }
  }
}

@media screen and (max-width: 470px) {
  .card {
    width: 300px;
    height: 150px;
    img {
      width: 160%;
      height: auto;
    }
    margin: 0 auto;
  }
  .widecard {
    width: 300px;
    height: 150px;
    img {
      width: 160%;
    }
  }
  .card__thumbnail {
    .text {
      width: 100%;
      // position: absolute;
      // left: -1.3rem;
      // bottom: 2rem;
      .card__category {
        font-size: 20px;
      }
      .card__title {
        font-size: 20px;
      }
    }

    .card__category {
      align-self: flex-end;
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.3rem;
      line-height: 1;
      font-weight: 400;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
