a {
  color: var(--aqua-dark);
}

#textdummy {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  font-size: 20px;
  .key {
    font-size: 23px;
  }
}

.bg-pc {
  margin: 0 auto;
  background-image: url(../../Assets/laptop.png);
  background-size: cover;
  background-position: center center;
  // width: 1100px;
  // height: 900px;
  width: 1000px;
  height: 582px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 755px;
    height: 488px;
    position: absolute;
    top: 29px;
    right: 123px;
  }
}

.bg-tablet {
  margin: 0 auto;
  background-image: url(../../Assets/tablet-png-6780.png);
  background-size: cover;
  background-position: center center;
  width: 970px;
  height: 650px;
  position: relative;

  img {
    width: 863px;
    height: 537px;
    position: absolute;
    top: 57px;
    right: 52px;
  }
}

@media (max-width: 1130px) {
  .bg-tablet {
    width: 776px;
    height: 520px;
    img {
      width: 690px;
      height: 429px;
      top: 45px;
      right: 44px;
    }
  }
}

@media (max-width: 900px) {
  .bg-tablet {
    width: 621px;
    height: 416px;
    img {
      width: 552px;
      height: 343px;
      top: 36px;
      right: 34px;
    }
  }
}

@media (max-width: 710px) {
  .bg-tablet {
    width: 497px;
    height: 333px;
    img {
      width: 441.6px;
      height: 274px;
      top: 29px;
      right: 26px;
    }
  }
}

@media (max-width: 560px) {
  .bg-tablet {
    width: 442.5px;
    height: 295px;
    img {
      width: 395px;
      height: 245px;
      top: 25px;
      right: 25px;
    }
  }
}

@media (max-width: 500px) {
  .bg-tablet {
    width: 376.1px;
    height: 250.75px;
    img {
      width: 335.75px;
      height: 208.25px;
      top: 21px;
      right: 21px;
    }
  }
}

@media (max-width: 420px) {
  .bg-tablet {
    width: 320px;
    height: 213.1px;
    img {
      width: 285.4px;
      height: 177.01px;
      top: 18px;
      right: 18px;
    }
  }
}

@media (max-width: 1300px) {
  .bg-pc {
    width: 880px;
    height: 510px;
    img {
      width: 660px;
      height: 430px;
      top: 22px;
      right: 111px;
    }
  }
}

@media (max-width: 1020px) {
  .bg-pc {
    width: 704px;
    height: 408px;
    img {
      width: 528px;
      height: 344px;
      top: 19px;
      right: 89px;
    }
  }
}

@media (max-width: 800px) {
  .bg-pc {
    width: 563px;
    height: 326.5px;
    img {
      width: 422px;
      height: 275px;
      top: 14px;
      right: 69px;
    }
  }
}

@media (max-width: 640px) {
  .bg-pc {
    width: 416px;
    height: 240px;
    img {
      width: 312px;
      height: 203px;
      top: 10px;
      right: 52px;
    }
  }
}

@media (max-width: 470px) {
  .bg-pc {
    width: 353.6px;
    height: 204px;
    img {
      width: 265.2px;
      height: 172.55px;
      top: 8px;
      right: 44px;
    }
  }
}

@media (max-width: 400px) {
  .bg-pc {
    width: 300.5px;
    height: 173.4px;
    img {
      width: 225.42px;
      height: 146.6px;
      top: 8px;
      right: 37px;
    }
  }
}

.bg-phone {
  margin: 0 auto;
  background-image: url(../../Assets/iphone.png);
  background-size: cover;
  background-position: center center;
  // width: 970px;
  // height: 970px;
  width: 325px;
  height: 650px;
  position: relative;

  img {
    width: 285px;
    height: 587px;
    position: absolute;
    top: 34px;
    right: 9px;
  }
}

.modal {
  #center-text {
    text-align: center;
    font-size: 35px;
    color: var(--aqua-dark);
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    &.dark-mode {
      color: white;
    }
  }
}

.tst {
  width: 40vh;
  height: 40vh;
  border-radius: 50%;
  margin: 3.5%;
}

.wrapper3 {
  margin: 0 auto;

  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--aqua-dark);
  padding: 2.5%;

  border-width: 5px;
  margin: 0 auto;
  &.dark-mode {
    border-color: white;
  }
}
.wrapper33 {
  margin: 0 auto;
  width: 40vh;
  height: 40vh;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--aqua-mid);
  padding: 1%;
  margin: 5%;
  border-width: 5px;
  margin: 0 auto;
  margin: 3%;
}
.wrapper3 img {
  // width: 100%;
  height: 100%;
}

.outherCircle3 {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  justify-content: center;
}
.circleBorder3 {
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 65%;

  top: 65%;
}
.infoCircle3 {
  background: var(--aqua-dark);
  opacity: 0.9;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 10%;
  text-align: center;
  color: white;
  &.dark-mode {
    background: white;
  }
}
.infoCircle33 {
  background: var(--aqua-mid);
  background: var(--aqua-dark);
  opacity: 0.9;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 10%;
  text-align: center;
  color: white;
  &.dark-mode {
    background: white;
  }
}
#imge2 {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}
.outherCircle3 .infoCircle3 {
  left: 50%;
}

.outherCircle3:hover .circleBorder3 {
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all 1s;
  top: 0%;
}
.outherCircle3:hover {
  cursor: pointer;
}
.disable-text-selection {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}
.infoCircle3 p {
  display: block;
  width: 100%;
  position: absolute;
  margin-top: 7%;
  font-size: 32px;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
}
.outherCircle3:hover .infoCircle3 p {
  opacity: 0.9;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  font-size: 46px;
  top: 35%;
  text-align: center;
  color: white;
  &.dark-mode {
    background: white;
  }
}
.outherCircle3:hover .infoCircle3 {
  border-radius: 50%;
}

.infoCircle33 p {
  display: block;
  width: 100%;
  position: absolute;
  top: 15%;
  font-size: 25px;
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
}

.infoCircle3 span {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 20px;
}

::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--aqua-dark);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--aqua-dark);
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}
.modal {
  z-index: 999;
  h2,
  h3 {
    animation: fadeIn 3s;
  }
  p {
    animation: fadeIn 3.5s;
  }
}
.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

#modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f9f9f9;
  padding: 14px 28px;
  border-radius: 3px;
  width: 85%;
  text-align: center;
  max-height: 90%;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 20px;
  height: auto;
  &.dark-mode {
    background-color: var(--gray);
  }
}

.close-modal {
  position: absolute;
  top: 1.5%;
  right: 10px;
  padding: 5px 7px;
  &:hover {
    cursor: pointer;
  }
}

.div1 {
  margin-right: 11px;
  margin-left: 0.5%;
  margin-top: 1%;
}
.div2 {
  margin-right: 11px;
  margin-top: 1%;
}
.div3 {
  margin-top: 1%;
}
.div11 {
  margin-bottom: 7px;
}
.div12 {
  margin-bottom: 7px;
}
.div122 {
  margin-bottom: 7px;
  margin-left: -10px;
}

.class6 {
  border-top: 3px solid;
  color: #d5eef7;
}
#textdummy {
  text-align: left;

  text-align: justify;
  text-justify: inter-word;
  width: 100%;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  &.dark-mode {
    color: white;
  }
  .virgule::after {
    content: " , ";
  }
  .virgule:last-child::after {
    content: " .";
  }
}
.App {
  text-align: center;
  justify-content: center;
}
.divstyle {
  border-radius: 15px;
  background: #eaf6fb;

  border-style: solid;
  border-width: 5px;
  border-color: #dbf0f8;

  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}
.divstyle2 {
  width: 33%;

  margin: 1%;
}
.divstyle2 .imagestyle {
  width: 100%;
}
.divstyle2 .imagestyle1 {
  width: 100%;
  border-top-left-radius: 15px;
}
.divstyle2 .imagestyle2 {
  width: 85%;
}
.divstyle2 .imagestyle3 {
  width: 100%;

  border-bottom-left-radius: 15px;
}
.divstyle2 .imagestyle4 {
  width: 85%;
}
.divstyle2 .imagestyle5 {
  width: 100%;
  border-top-right-radius: 15px;
}
.divstyle2 .imagestyle6 {
  width: 80%;
  border-bottom-right-radius: 15px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.circle2 {
  border-radius: 50%;
  width: 250px;
  height: 240px;
  padding: 9px;
}

.circle3 {
  border-radius: 50%;
  width: 250px;
  height: 240px;
  padding: 9px;
}

.try {
  display: flex;
  flex-direction: row;
  max-width: fit-content;
}

#class11 {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  color: var(--aqua-dark);
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  &.dark-mode {
    color: white;
  }
}

#classtitle2 {
  color: #05949b;
  margin-top: -4%;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  &.dark-mode {
    color: white;
  }
}
#class12 {
  font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 20px;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;

  &.dark-mode {
    color: white;
  }
}
.class2 {
  justify-content: center;

  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: -4%;
}
.class22 {
  justify-content: center;

  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: -4%;
}

.class4 {
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  border-color: #015866;

  margin: 40px;
}

.class41 {
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  border-color: var(--aqua-mid);
  padding: 1px;

  margin: 40px;
}

.class3 {
  text-align: center;
  width: 100%;

  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
}

.c2 {
  justify-content: center;

  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-top: 3%;
  row-gap: 0.5rem;
}

.class5 {
  margin: 0 auto;
}

#showdiv {
  margin-top: 3.4%;
}

.show-all-style {
  width: 35px;
  height: 25px;
  cursor: pointer;
}

.testclass {
  margin-left: 6px;
  margin-top: 18px;
  margin-right: 50px;
  margin-bottom: 50px;
}
.testclass1 {
  margin-left: 6px;
  margin-top: 18px;
  margin-right: 50px;
  margin-bottom: 50px;
  border-radius: 15px;
}
.back-class {
  position: absolute;

  margin-left: 0px;
  padding: 5px 7px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#PortfolioSection {
  &.dark-mode {
    background-color: var(--gray);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
