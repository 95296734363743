// Mobile Version
.carousel {
  .meet-leader-link {
    // font-size: 1.8rem;
    // width: 6em;
    // padding-left: 1.8rem;
    // padding-right: 0.5rem;
    // padding-top: 0.8rem;
    // padding-bottom: 0.8rem;
    font-size: 4vh;
    padding: 1.6vh 1.8vh;
    background-color: #05949b;
    color: white;
    border-radius: 50px;
    font-family: "BTekUp-Font";
  }
  .left-arrow,
  .right-arrow {
    display: block;
    width: 16%;
    height: 70%;
    position: absolute;
    z-index: 3;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      width: 0.7em;
    }
    .cls-1 {
      fill: white;
      transition: opacity var(--transition-time);
    }
    &:hover {
      .cls-1 {
        opacity: 1;
      }
    }
  }
  .left-arrow {
    left: 0;
  }
  .right-arrow {
    right: 0;
  }
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  word-spacing: -1;
  .carousel-slide {
    transition: left 1s ease-in-out;
    &.prev-slide {
      position: absolute;
      left: -100%;
    }
    &.current-slide {
      left: 0;
      z-index: 1;
    }
    &.next-slide {
      position: absolute;
      left: 100%;
    }

    position: relative;
    display: inline-block;
    width: 100%;
    height: 80vh;
    .carousel-image {
      width: 100%;
      height: 100%;
      position: relative;
      filter: brightness(25%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .blur-filter {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(5px);
      }
    }
    .carousel-text {
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: 8vh;
      width: 90%;
      white-space: initial;
      color: white;
      text-align: center;
      line-height: 80%;
      position: absolute;
      bottom: 55%;
      left: 50%;
      transform: translateX(-50%);
      .up {
        width: 7vh;
        vertical-align: middle;
        padding: 0 2px 0 3px;
        opacity: 1;
        .cls-1 {
          opacity: 1;
          fill: var(--aqua-very-light);
        }
      }
    }
  }
  .carousel-buttons {
    position: absolute;
    width: 90%;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .carousel-btn {
      box-sizing: border-box;
      background-color: var(--aqua-mid);
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      color: white;
      font-size: 4vh;
      width: 25vh;
      border-radius: 500px;
      padding: 1.6vh 1.5vh;
      margin-bottom: 0.5em;
      cursor: pointer;
      opacity: 1;
      transition: opacity var(--transition-time);
      &.aqua-dark {
        background-color: var(--aqua-dark);
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &::before {
    content: "";
    background-color: var(--white);
    transition: background-color var(--transition-time);
    position: absolute;
    width: 125vw;
    height: 13vh;
    border-radius: 100%;
    left: 50%;
    z-index: 10;
    bottom: 0;
    transform: translate(-50%, 70%);
    box-shadow: 0 -0.1em 10px 1px var(--aqua-dark);
  }
}
.scroll-btn {
  width: 1.7em;
  position: absolute;
  right: 50%;
  bottom: 10%;
  transform: translateX(50%);
  z-index: 10;
  cursor: pointer;
  .cls-1 {
    fill: white;
    opacity: 1;
  }
  .scroller {
    animation: scroll 3s infinite;
  }
  @keyframes scroll {
    0% {
      transform: translateY(5%);
      opacity: 1;
    }
    20% {
      transform: translateY(35%);
      opacity: 1;
    }
    70% {
      transform: translateY(35%);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

#home-section {
  position: relative;
  &.dark-mode {
    background-color: var(--gray);
    .carousel {
      &::before {
        background-color: var(--gray);
        box-shadow: 0 -0.1em 10px 1px var(--aqua-very-light);
      }
    }
  }
}

// Desktop Version
@media (min-width: 1024px) {
  .carousel {
    .left-arrow,
    .right-arrow {
      svg {
        width: 1em;
      }
    }
    .carousel-slide {
      height: 100vh;
      .carousel-text {
        font-size: 9rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 20%;
        bottom: 40%;
        left: 50%;
        transform: translate(-50%, 50%);
        .up {
          width: 7.5rem;
        }
      }
    }
    .carousel-buttons {
      bottom: 35%;
      transform: translate(-50%, 50%);
      width: 29%;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      .carousel-btn {
        box-sizing: content-box;
        font-size: 1.8rem;
        width: 6em;
        padding: 0.5em 0.6em;
        &.aqua-dark {
          margin-left: 0.6em;
        }
      }
      .meet-leader-link {
        // font-size: 1.8rem;
        // width: 6em;
        // padding-left: 1.8rem;
        // padding-right: 0.5rem;
        // padding-top: 0.8rem;
        // padding-bottom: 0.8rem;
        // font-size: 4vh;
        // padding: 1.6vh 1.8vh;
        box-sizing: content-box;
        font-size: 1.8rem;
        // width: 6em;
        padding: 0.5em 0.6em;
        background-color: #05949b;
        color: white;
        border-radius: 50px;
        font-family: "BTekUp-Font";
      }
    }
  }
  .scroll-btn {
    width: 2em;
  }
}
