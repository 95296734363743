#loading-screen {
  position: fixed;
  top: 0;
  background-color: var(--white);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220vw;
    .cls-15 {
      animation: rotation-of-three-shapes 3s linear infinite;
      transform-origin: center;
    }
    .cls-17 {
      animation: rotation-of-three-shapes 3s linear infinite;
      animation-direction: reverse;
      transform-origin: center;
    }    
  }
}

@keyframes rotation-of-three-shapes {
  0% {
    transform: rotate(0) translate(0.1%, -2.1%);
  }
  100% {
    transform: rotate(360deg) translate(0.1%, -2.1%);
  }
}

#loading-screen.load-end-phase-1 {
  svg {
    polyline,
    .cls-8,
    .cls-7 {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: polyline-animation 3.5s linear reverse forwards;
    }
    .cls-9{
      animation: dots 3.5s linear forwards;
    }
  }
}
@keyframes polyline-animation {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dots {
  100%{
    opacity: 0;
  }
}
.load-end-phase-2 {
  animation: fade-out 1s forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  #loading-screen {
    svg {
      width: 100vw;
    }
  }
}
