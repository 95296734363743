#sectorsection {
  &.dark-mode {
    background-color: #323b3f;
    margin-bottom: -2.1rem;
  }
}

#sectortitle {
  &.dark-mode {
    color: red;
  }
}

@media only screen and (min-width: 1001px) {
  .secdiv {
    width: 90%;

    position: relative;
    min-height: 1px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
  #divsectortitle {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: 5%;
  }
  .circle {
    width: 31vh;
    height: 29vh;
    border-radius: 50%;
    border: 6px solid #1bbed0;
    background: #015866;
    color: #cecece;
    margin: 0 auto 40px;
    position: relative;
    cursor: pointer;

    overflow-wrap: break-word;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .circle:hover {
    transition: all 0.6s;
    border: 6px solid #015866;
    background: #1bbed0;
    color: white;
    overflow-wrap: break-word;
    transform: scale(1.1);

    .faff {
      color: #015866;
    }
  }
  .fff {
    overflow-wrap: break-word;
    margin-top: 10%;
    font-size: 27px;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
  }

  .faff {
    color: white;
  }
  .newdivseccc {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
  }

  #sectortitle {
    color: #015866;
    text-align: center;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    font-size: 4rem;
    margin: 0 auto;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
    &.dark-mode {
      color: white;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .newdivseccc {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
  }
  .secdiv {
    width: 100%;

    position: relative;
    min-height: 1px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: flex;
    flex-direction: column;
  }
  #divsectortitle {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: 10%;
    &.dark-mode {
      color: white;
    }
  }
  .circle {
    width: 35vh;
    height: 35vh;
    border-radius: 50%;
    border: 6px solid #1bbed0;
    background: #015866;
    color: #cecece;
    margin: 0 auto 40px;
    position: relative;
    cursor: pointer;

    overflow-wrap: break-word;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .circle:hover {
    transition: all 0.6s;
    border: 6px solid #015866;
    background: #1bbed0;
    color: white;
    overflow-wrap: break-word;
    .faff {
      color: #015866;
    }
  }
  .fff {
    overflow-wrap: break-word;
    margin-top: 10%;
    font-size: 17pt;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
  }

  .faff {
    color: white;
  }
  #sectortitle {
    color: #015866;
    text-align: center;
    font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
    font-size: 4rem;
    margin: 0 auto;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
    &.dark-mode {
      color: white;
    }
  }
}

@media only screen and (max-width: 1024px) {
  html:not(.no-js) [data-aos^="flip-up"][data-aos^="flip-up"] {
    opacity: 1;
    transition-property: none;
    transition-property: none;
    transform: none;
    /* transition-property: opacity,transform,-webkit-transform; */
  }
}
