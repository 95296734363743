// Mobile Version
// Common style between all navbars
.nav-bar {
  width: 100%;
  z-index: 100;
  ul {
    margin: 0 var(--margin);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    li {
      display: flex;
      align-items: center;
    }
    .nav-logo {
      width: 4em;
      margin: 0.4em 0;
      img {
        width: 100%;
        vertical-align: top;
      }
    }
    li.nav-btn {
      display: none;
    }
    .right-container {
      display: flex;
      justify-content: space-between;
      .toggle-btn {
        cursor: pointer;
        svg {
          width: 2.5em;
          margin: 0;
        }
      }
      .toggle-btn-off {
        .circle {
          transition: var(--transition-time);
        }
      }
      .toggle-btn-on {
        .cls-1 {
          opacity: 1;
        }
        .circle {
          transition: var(--transition-time);
          transform: translateX(38%);
        }
      }
      .show-nav-btn {
        margin-left: 1em;
        cursor: pointer;
        svg {
          width: 1.6em;
        }
      }
    }
    .active-slider {
      display: none;
    }
  }
}

// Welcome NavBar style
.welcome-nav-bar {
  position: absolute;
  top: 0;
  .show-nav-btn {
    transition: opacity var(--transition-time);
    svg {
      fill: white;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}

// Sticky NavBar style
.sticky-nav-bar {
  transition: background-color var(--transition-time);
  background-color: var(--white);

  position: fixed;
  top: 0;
  transition: var(--transition-time);
  box-shadow: var(--shadow);
  .toggle-btn-off {
    .cls-1,
    .cls-2 {
      fill: var(--gray);
    }
    .circle {
      transition: var(--transition-time);
    }
  }
  .toggle-btn-on {
    .cls-1,
    .cls-2 {
      fill: var(--gray);
      opacity: 1;
    }
  }
  .show-nav-btn {
    svg {
      fill: var(--gray);
      transition: fill var(--transition-time);
    }
    &:hover {
      svg {
        fill: var(--aqua-mid);
      }
    }
  }
  // Dark Mode Style
  &.dark-mode {
    background-color: var(--gray);
    .toggle-btn-on {
      .cls-1,
      .cls-2 {
        fill: var(--white);
        opacity: 1;
      }
    }
    .show-nav-btn {
      transition: opacity var(--transition-time);
      svg {
        fill: var(--white);
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

// Mobile NavMenu Style
.mobile-nav-menu {
  background-color: var(--white);
  z-index: 1000;
  position: fixed;
  overflow-y: auto;
  top: 0;
  // right: -120%;
  height: 100vh;
  width: 100%;
  transition: all var(--transition-time) ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 3em 0;
    align-items: center;
    .nav-btn button,
    .nav-btn a,
    .drop-down-btn button {
      color: var(--aqua-dark);
      font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
      font-size: var(--nav-font-size);
      height: 100%;
      cursor: pointer;
      transition: color var(--transition-time);
      &:hover {
        color: var(--aqua-mid);
      }
    }
    .arrow-down {
      width: 0.7em;
      margin-left: 0.3em;
      .cls-1 {
        fill: var(--aqua-dark);
      }
    }
    .drop-down-menu {
      display: none;
      position: fixed;
      width: max-content;
      height: max-content;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
      z-index: 1001;
      background-color: var(--white);
      border-radius: var(--border-radius);
      box-shadow: var(--shadow);
      .drop-down-btn {
        padding: 0.2em 1em;
        width: 100%;
        font-size: 1.5em;
      }
      li:first-of-type .drop-down-btn {
        padding-top: 0.4em;
      }
      li:last-of-type .drop-down-btn {
        padding-bottom: 0.4em;
      }
    }
    .nav-drop-btn button:hover + .drop-down-menu,
    .drop-down-menu:hover {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .close-btn {
      position: absolute;
      right: var(--margin);
      top: 1em;
      cursor: pointer;
      svg {
        width: var(--nav-font-size);
        .cls-1 {
          fill: var(--aqua-dark);
          transition: fill var(--transition-time);
          opacity: 1;
        }
      }
      &:hover {
        .cls-1 {
          fill: var(--aqua-mid);
        }
      }
    }
    li .active-menu-btn {
      color: var(--aqua-mid);
    }
  }
  // To make it slide into the view this class is applied
  &.visible-menu {
    transform: translateX(0);
  }
  &.invisible-menu {
    transform: translateX(-100%);
  }
  // Dark Mode Style
  &.dark-mode {
    background-color: var(--gray);
    .nav-btn button,
    .drop-down-btn button {
      color: white;
      transition: opacity var(--transition-time);
      &:hover {
        color: white;
        opacity: 0.5;
      }
    }
    .arrow-down {
      .cls-1 {
        fill: white;
      }
      &:hover {
        .cls-1 {
          opacity: 0.5;
        }
      }
    }
    .drop-down-menu {
      background-color: var(--gray);
    }
    .close-btn {
      svg .cls-1 {
        fill: white;
        transition: opacity var(--transition-time);
      }
      &:hover {
        svg .cls-1 {
          fill: white;
          opacity: 0.5;
        }
      }
    }
    li .active-menu-btn {
      opacity: 0.7;
    }
  }
}

// Desktop Version
@media (min-width: 1080px) {
  // Disappear on Desktop
  .mobile-nav-menu {
    display: none;
  }

  // Common style between all navbars
  .nav-bar {
    width: 100%;
    ul {
      margin: 0 var(--margin);
      display: flex;
      justify-content: space-around;
      align-items: stretch;
      li {
        display: flex;
        align-items: center;
        position: relative;
      }
      li.nav-btn {
        display: flex;
      }
      .nav-logo {
        width: 4.5em;
        margin: 0.4em 1em;
      }
      .nav-btn button,
      .drop-down-btn button {
        font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
        font-size: var(--nav-font-size);
        cursor: pointer;
        height: 100%;
      }
      .nav-btn a {
        font-family: "BTekUp-Font", Arial, Helvetica, sans-serif;
        font-size: var(--nav-font-size);
        cursor: pointer;
        height: 100%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .nav-btn .sticky {
        color: var(--aqua-dark);
      }
      .arrow-down {
        width: 0.7em;
        margin-left: 0.3em;
      }
      .drop-down-menu {
        display: none;
        position: absolute;
        z-index: 100;
        top: 100%;
        margin: 0;
        width: 110%;
        height: auto;
      }
      .nav-drop-btn button:hover + .drop-down-menu,
      .drop-down-menu:hover {
        display: flex;
        flex-direction: column;
        border-radius: var(--border-radius);
        .drop-down-btn {
          padding-top: 0.2em;
          padding-bottom: 0.2em;
          width: 100%;
          text-align: start;
        }
        li:first-of-type .drop-down-btn {
          padding-top: 0.4em;
        }
        li:last-of-type .drop-down-btn {
          padding-bottom: 0.4em;
        }
      }
      .right-container {
        .toggle-btn {
          margin-left: 3em; // To separate the toggle button from the nav ->1
          cursor: pointer;
          svg {
            width: 3em;
          }
        }
        .toggle-btn-off {
          &:hover .circle {
            opacity: 1;
          }
        }
        .show-nav-btn {
          display: none;
        }
      }
      .active-slider {
        display: block;
        height: 0.15em;
        position: absolute;
        transition: var(--transition-time);
      }
    }

    > ul > li:first-child {
      /*Home Button*/
      margin-left: 4em; // 1-> To Recenter the logo, must be the same
    }
  }

  // Welcome NavBar style
  .welcome-nav-bar {
    .nav-btn button,
    .drop-down-btn {
      color: white;
      transition: opacity var(--transition-time);
      &:hover {
        opacity: 0.7;
      }
    }
    .active-slider {
      background-color: white;
    }
    .arrow-down {
      .cls-1 {
        fill: white;
      }
    }
    .nav-drop-btn button:hover + .drop-down-menu,
    .drop-down-menu:hover {
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      .active-drop-btn {
        opacity: 0.5;
      }
    }
  }

  // Sticky NavBar style
  .sticky-nav-bar {
    .nav-btn button,
    .drop-down-btn {
      color: var(--aqua-dark);
      transition: color var(--transition-time);
      &:hover {
        color: var(--aqua-mid);
      }
    }
    .active-slider {
      background-color: var(--aqua-dark);
    }
    .arrow-down .cls-1 {
      fill: var(--aqua-dark);
    }
    .nav-drop-btn button:hover + .drop-down-menu,
    .drop-down-menu:hover {
      box-shadow: var(--shadow);
      background-color: var(--white);
      backdrop-filter: none;
      .active-drop-btn {
        opacity: 1;
        color: var(--aqua-light);
      }
    }
    .toggle-btn-off {
      &:hover .circle {
        opacity: 1;
      }
    }

    // Dark Mode style
    &.dark-mode {
      .nav-btn button,
      .drop-down-btn {
        color: white;
        transition: opacity var(--transition-time);
        &:hover {
          opacity: 0.7;
        }
      }
      .active-slider {
        background-color: white;
      }
      .arrow-down .cls-1 {
        fill: white;
      }
      .nav-drop-btn button:hover + .drop-down-menu,
      .drop-down-menu:hover {
        background-color: var(--gray);
        .active-drop-btn {
          opacity: 0.5;
          color: white;
        }
      }
    }
  }
}
